/**
 * Created by Ivan on 17.10.2024
 */

var OpenLetterButtonBooster = BaseButtonBooster.extend({
    ctor: function (booster, scene) {
        this.booster = booster;

        this.shadow = new ShadowLayer();
        this.shadow.setLocalZOrder(OpenLetterButtonBooster.SHADOW_Z);
        
        this.keypadView = scene.keypadView;
        this.boardView = scene.boardView;
        this.hintButton = scene.hintButton;

        this._super({
            booster: booster,
            styles: cleverapps.styles.OpenLetterButtonBooster
        });

        booster.on("afterExecute", this.createListener(this.afterExecute.bind(this)), this);
    },

    createIcon: function () {
        return new cc.Sprite(bundles.buttons.frames.open_letter_png);
    },

    runMagnifierBoardAnimation: function () {
        var styles = cleverapps.styles.OpenLetterButtonBooster.magnifier;
        var grid = this.boardView.grid;

        this.removeCloseButton();

        this.magnifier.stopAllActions();

        this.magnifier.replaceParentSamePlace(grid);
        var startPosition = this.magnifier.calculatePositionRound(styles.start);
        var finishPosition = this.magnifier.calculatePositionRound(styles.finish);

        var movingNode = cleverapps.scenes.getMovingNode();
        this.magnifier.replaceParentSamePlace(movingNode);
        var position1 = movingNode.convertToNodeSpace(grid.convertToWorldSpace(startPosition));
        var position2 = movingNode.convertToNodeSpace(grid.convertToWorldSpace(finishPosition));

        this.magnifier.runAction(new cc.Sequence(
            new cc.MoveTo(0.5, position1.x, position1.y),
            new cc.MoveTo(0.8, position2.x, position2.y),
            new cc.RemoveSelf()
        ));
    },

    addMagnifier: function () {
        var magnifier = this.magnifier = new cleverapps.Spine(bundles.game.jsons.open_letter_magnifer_json);
        this.magnifier.avoidNode = "MagnifierAnimation";
        magnifier.setAnimation(0, "keypad", true);
        magnifier.setAnchorPoint(0.25, 0.75);
        this.keypadView.addChild(magnifier);

        var startLetter = this.keypadView.letters[0];
        var startPosition = cc.p(startLetter.x, startLetter.y);
        magnifier.setPositionRound(startPosition);
    },

    addCloseButton: function () {
        var styles = cleverapps.styles.OpenLetterButtonBooster.button;

        var button = this.closeButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_green,
            onClicked: function () {},
            text: "Cancel",
            width: styles.width,
            height: styles.height
        });
        button.setVisible(false);

        this.keypadView.addChild(button);
        button.setPositionRound(styles.position);
    },

    runMagnifierKeypadAnimation: function () {
        var styles = cleverapps.styles.OpenLetterButtonBooster.magnifier;

        this.addMagnifier();
        this.addCloseButton();

        var idlePosition = this.magnifier.calculatePositionRound(styles.idlePosition);

        var movingNode = cleverapps.scenes.getMovingNode();
        this.magnifier.replaceParentSamePlace(movingNode);

        var endLetter = this.keypadView.letters[1];
        var endPosition = movingNode.convertToNodeSpace(this.keypadView.convertToWorldSpace(cc.p(endLetter.x, endLetter.y)));

        var keypadCenter = this.keypadView.convertToWorldSpace(cc.p(this.keypadView.width / 2, this.keypadView.height / 2));
        var centerPoint = movingNode.convertToNodeSpace(keypadCenter);
        var rotateAngle = 2 * Math.PI - Math.atan2(endPosition.y - centerPoint.y, endPosition.x - centerPoint.x);

        idlePosition = movingNode.convertToNodeSpace(this.keypadView.convertToWorldSpace(idlePosition));

        this.magnifier.runAction(new cc.Sequence(
            new cc.Spawn(
                new cc.MoveInArc(1, centerPoint, rotateAngle).easing(cc.easeOut(1.1)),
                new cc.RotateBy(1, -60)
            ),
            new cc.Spawn(
                new cc.MoveTo(0.75, idlePosition.x, idlePosition.y).easing(cc.easeBackOut()),
                new cc.RotateBy(0.75, 60).easing(cc.easeBackIn())
            ),
            new cc.CallFunc(function () {
                this.magnifier.setAnimation(0, "idle", true);
                cleverapps.scenes.onAvoidNodeVisibilityChanged();
            }.bind(this))
        ));

        this.closeButton.runAction(new cc.Sequence(
            new cc.DelayTime(1.5),
            StandartAnimations.showUp(this.closeButton)
        ));
    },

    removeMagnifierAnimation: function () {
        this.magnifier.stopAllActions();
        this.magnifier.runAction(new cc.Sequence(
            StandartAnimations.disappear(this.magnifier),
            new cc.CallFunc(function () {
                this.magnifier.clearTrack(0);
            }.bind(this)),
            new cc.RemoveSelf(),
            new cc.CallFunc(function () {
                this.magnifier = undefined;
            }.bind(this))
        ));

        this.removeCloseButton();
    },

    removeCloseButton: function () {
        this.closeButton.runAction(new cc.Sequence(
            StandartAnimations.disappear(this.closeButton),
            new cc.RemoveSelf(),
            new cc.CallFunc(function () {
                this.closeButton = undefined;
            }.bind(this))
        ));
    },

    onExecute: function () {
        if (!cleverapps.forces.isRunningForce()) {
            this.shadow.createShadow();
            this.moveUp();
        }

        this.runMagnifierKeypadAnimation();

        this.clickHandler = cc.eventManager.addListener({
            event: cc.EventListener.TOUCH_ONE_BY_ONE,
            swallowTouches: true,
            onTouchBegan: function (touch) {
                var sceneBox = this.keypadView.getSceneBoundingBox();
                var position = cleverapps.scenes.getRunningScene().convertTouchToNodeSpace(touch);
                if (!cc.rectContainsPoint(sceneBox, position) && !cleverapps.forces.isRunningForce()) {
                    this.removeMagnifierAnimation();
                    this.booster.afterExecute(500);
                    this.afterExecute(true);
                }
            }.bind(this)
        }, this.shadow);
    },

    afterExecute: function (cancel) {
        if (!cleverapps.forces.isRunningForce()) {
            this.shadow.deleteShadow();
            this.moveDown();
        }
        
        cc.eventManager.removeListener(this.clickHandler);

        if (!cancel) {
            this.runMagnifierBoardAnimation();
        }

        this.keypadView.letters.forEach(function (letter) {
            letter.enable();

            if (letter.animation) {
                letter.animation.removeFromParent();
            }

            if (letter.hoverHandler) {
                letter.hoverHandler.remove();
            }
        });

        this.keypadView.shuffleButton.show();
    },

    moveUp: function () {
        [this.keypadView, this.boardView, this.hintButton, this.parent].forEach(function (element) {
            element.oldZ = element.getLocalZOrder();
            element.setLocalZOrder(OpenLetterButtonBooster.SHADOW_Z + 1);
        });
    },

    moveDown: function () {
        [this.keypadView, this.boardView, this.hintButton, this.parent].forEach(function (element) {
            element.setLocalZOrder(element.oldZ);
            delete element.oldZ;
        });
    }
});

OpenLetterButtonBooster.SHADOW_Z = 11;

cleverapps.styles.OpenLetterButtonBooster = {
    width: 150,
    height: 120,

    position: [
        { x: { align: "left", dx: 15 }, y: { align: "bottom", dy: 350 } },
        { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 465 } },
        { x: { align: "right", dx: -10 }, y: { align: "bottom", dy: 465 } }
    ],
    phone: {
        scale: 0.9
    },
    content: {
        margin: -20,
        direction: cleverapps.UI.VERTICAL,
        layout: { x: { align: "center", dx: 0 }, y: { align: "center", dy: 20 } },
        freeBlock: { textWidth: 120 }
    },

    button: {
        position: [
            { x: { align: "center", dx: -295 }, y: { align: "top", dy: 15 } },
            { x: { align: "center", dx: 400 }, y: { align: "center", dy: 0 } },
            { x: { align: "center", dx: 400 }, y: { align: "center", dy: 0 } }
        ],
        width: 200,
        height: 80
    },

    magnifier: {
        start: { x: { align: "left", dx: -100 }, y: { align: "center" } },
        finish: { x: { align: "right", dx: 100 }, y: { align: "center" } },
        idlePosition: [
            { x: { align: "center", dx: 200 }, y: { align: "center", dy: 310 } },
            { x: { align: "center", dx: 400 }, y: { align: "center", dy: 200 } },
            { x: { align: "center", dx: 400 }, y: { align: "center", dy: 200 } }
        ]
    }
};